@import "./variables";
@import "./navbar";
@import "./omoss";
@import "./personSide";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Open+Sans:wght@300;400&display=swap');
@import "./home";
@import "./loggInn";
@import "./rapport";
@import "./project";
@import "./rapporteringer";
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    background-color: $page_background;
    display: flex;
    justify-content: center;


    p {
        font-family: 'Open Sans';
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Lato';
        font-weight: 700;
    }


    .App {
        width: 100vw;
        height: 100vh;
    
        color: white;
        a {
            color: white;
        }

        .layout {
                width: 100%;
        }

        
        .noside_404 {
            min-width: 100%;
            min-height: 100vh;
            background-color: $page_background;

            color: white;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            h1 {
                margin-top: 5rem;
            }

            p {
                margin-top: 2rem;
                a {
                    cursor: pointer;
                    color: $nxtech_light;
                    font-size: 2rem;
                }
            }
        }
    }


    @media screen and (min-width: 1401px)  {

        .App {
            min-width: 100%;

            
        }

    }
    @media screen and (max-width: 1400px)  {

        .App {
            width: 100vw;
        }
    }
}