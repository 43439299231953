
.page_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $header_h1_height;
        gap: 0.6rem;

        .prosjektlogo {
            width: 250px; 
        }

        h1 {
            font-size: 2.2rem;
        }

        h3 {
        }

        h4 {
            color: grey;
        }
    }


    main {
        display: flex;
        flex-direction: column;
        align-items: center;

        article {
            display: flex;
            flex-direction: column;
            max-width: 750px;

            h2 {
                margin-top: $header_h1_height;
            }
    


            p {
                margin-top: 1rem;
                
                &:nth-child(2) {
                    margin-top: 0;
                }
            }


        }

    }
}