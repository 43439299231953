$nav-bg: #242424;

$nxtech-dark: #1392a1;
$nxtech-darker: #0D97A4;
$nxtech-normal: #89e7ef;
$nxtech-light: #1ed9e9;
$nxtech-lighter: #1ba9b8;

$nxtech_slett_meg_rod_tekst: #f75249;
$nxtech_slett_meg_rod_hover: #da3733;
$nxtech_slett_meg_rod_border: #6c2b2b;

$nxtech-focus: #89e6ef63 ;
$nxtech-focus2: #8ecae6;

$nxtech-dark-lowOpacity: #1393a13f;

$primary-white: #ffffff;
$primary-black: #000000;

$header_h1_height: 2.5rem;

$page_background: #282c34;
