.logg_inn_container {
    display: flex;
    justify-content: center;
    font-family: "Lato";

    .logg_inn_form {
        display: flex;
        justify-content: center;

        flex-direction: column;
        align-items: center;
        label {
            align-self: flex-start;
            margin-top: 2rem;
            margin-bottom: 5px;
            font-size: 1.5rem;
        }

        input {
            font-size: 1.2rem;
            height: 50px;
            min-width: 250px;
            padding: 1px 1px 1px 1rem;
            outline: none;

            border-radius: 1.5rem;
            position: relative;
            background-color: white;

            &:focus {
                border: 1px solid $nxtech-dark;
                box-shadow: 0px 2px 7px $nxtech-light;
            }
        }

        button {
            margin-top: 2rem;
            width: 100px;
            height: 45px;
            border-radius: 0.3rem;

            cursor: pointer;

            &:hover {
                background-color: $nxtech-light;
            }

            &:active {
                background-color: $nxtech-dark;
            }
        }
    }
}





@media screen and (min-width: 2000px)  {
    .logg_inn_container {
        form {

            label {
                font-size: 5rem;
            }
    
            input {
                font-size: 4rem;
                height: 100px;
                min-width: 500px;
                padding: 2px 2px 2px 2rem;
                outline: none;
    
                border-radius: 1.5rem;
                position: relative;
                background-color: white;
    
                &:focus {
                    border: 3px solid $nxtech-dark;
                    box-shadow: 0px 2px 7px $nxtech-light;
                }
            }
    
            button {
                margin-top: 2rem;
                width: 200px;
                height: 90px;
                border-radius: 0.3rem;
                
                font-size: 2.5rem;
                cursor: pointer;
    
                &:hover {
                    background-color: $nxtech-light;
                }
    
                &:active {
                    background-color: $nxtech-dark;
                }
            }
        }
    }
    
    
}