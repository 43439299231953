.rapport_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    h1 {
        margin-top: 2rem;
    }

    .button_rapporteringer {
        width: 160px;
        height: 40px;
        font-size: 1.2rem;

        border-radius: 1.2rem;
        background-color: $nxtech-darker;
        color: $primary-white;
        outline: none;
        border: 1px solid white;

        cursor: pointer;
    }


    .buttons {
        margin-top: calc(1.5rem - 1rem);
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        button {
            min-width: 265px;
            height: 45px;
            cursor: pointer;
            font-size: 1.2rem;
            position: relative;
            background-color: $nxtech-darker;
            color: $primary-white;
            outline: none;

            border-radius: 0.3rem;
            border: 1px solid $primary-white;

            transition: filter .245s ease;

            &:hover {
                filter: brightness(1.2);

            }

            &:active {
                filter: brightness(0.65);
                border: solid 1px black;

            }
            
        }
    }

    .download_PDF {
        width: 120px;
        height: 2.5rem;
        background-color: transparent;
        outline: none;
        font-size: 1.2rem;
        color: $primary-white;

        border: 1px solid $primary-white;
        cursor: pointer;

        transition: filter .25s ease;

        &:hover {
            filter: brightness(1.2);
            background-color: $nxtech-darker;
        }

        &:active {
            filter: brightness(0.85);
            border: solid 1px black;
            background-color: $nxtech-darker;
        }
    }
        
}






@media screen and (min-width: 2000px) {
    .rapport_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    
        h1 {
            margin-top: 3rem;
            font-size: 5rem;
        }

        h2 {
            font-size: 5rem;
        }
    
        .button_rapporteringer {
            width: 320px;
            height: 75px;
            font-size: 2.5rem;
    
            border-radius: 2rem;
            background-color: $nxtech-darker;
            color: $primary-white;
            outline: none;
            border: 1px solid white;
    
            cursor: pointer;
        }
    
    
        .buttons {
            margin-top: calc(1.5rem - 1rem);
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
    
            button {
                min-width: 530px;
                height: 90px;
                cursor: pointer;
                font-size: 2.5rem;
                position: relative;
                background-color: $nxtech-darker;
                color: $primary-white;
                outline: none;
    
                border-radius: 0.3rem;
                border: 1px solid $primary-white;
    
                transition: filter .245s ease;
    
                &:hover {
                    filter: brightness(1.2);
    
                }
    
                &:active {
                    filter: brightness(0.65);
                    border: solid 1px black;
    
                }
                
            }
        }
    
        .download_PDF {
            width: 240px;
            height: 5rem;
            background-color: transparent;
            outline: none;
            font-size: 2.5rem;
            color: $primary-white;
    
            border: 1px solid $primary-white;
            cursor: pointer;
    
            transition: filter .25s ease;
    
            &:hover {
                filter: brightness(1.2);
                background-color: $nxtech-darker;
            }
    
            &:active {
                filter: brightness(0.85);
                border: solid 1px black;
                background-color: $nxtech-darker;
            }
        }
            
    }
    
    
}