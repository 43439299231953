@import "./variables";


.navbar {
    font-family: 'Lato';
    height: 80px;
    padding: 0 3.5rem;
    background-color: $nav-bg;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
        cursor: pointer;
    }

    .dropdown {
        cursor: pointer;
    }

    .links {
        ul {
            display: flex;
            gap: 2rem;

            li {
                list-style-type: none;
                display: flex;
                justify-content: center;
                align-items: center;

                transition: filter .35s ease;
                &:hover {
                    filter: brightness(1.2);
                }


                .activeLinkUnderline {
                    color: $nxtech-light;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        left: 0;
                        bottom: -2px;

                        background-color: $nxtech-dark;
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 1.2rem;

                    
                }


                .logg_inn_button {
                    width: 100px;
                    height: 30px;
                    background-color: $nxtech-dark;
                    border-radius: 1.5rem;
                    outline: none;
                    border: 0;
                    color: $primary_white;

                    font-size: 1rem;
                    padding: 2px;
                    cursor: pointer;
                    transition: filter .35s ease;

                    &:hover {
                        filter: brightness(1.2);
                    }

                    &:active {
                        filter: brightness(0.65);
                    }
                }
            }
        }
    }

    

    .dropdownclicked {
        position: absolute;
        background-color: $nav-bg;
        left: 0;
        top: 0;
        width: 100%;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        .links {
            width: 90%;


            ul {

                display: flex;
                justify-content: space-evenly;
                align-items: center;
                align-self: center;


                svg {
                    color: $nxtech-normal;
                }

                li {
                    cursor: pointer;

                    transition: filter .35s ease;
                    &:hover {
                        filter: brightness(1.2);
                    }



                    &:nth-last-child(1) {
                        margin: 0;
                        width: 30px;
                        height: 30px;
                    }



                }




            }
        }
    }




}










@media screen and (max-width: 950px) {

    .navbar {

        padding: 0 1rem;
    
        .logo {
            cursor: pointer;
        }
    
        .links {
            ul {
                display: flex;
                flex-direction: column;

    
                li {
                    transition: filter .35s ease;
                    &:hover {
                        filter: brightness(1.2);
                    }
    
                    .activeLinkUnderline {

    
                        &::after {

                        }
                    }
    
                    a {

                    }
                }
            }
        }
    
        .dropdownclicked {

    
            .links {
    
    
                ul {

    
                    svg {
                    }
    
                    li {
                        transition: filter .35s ease;
                        &:hover {
                            filter: brightness(1.2);
                        }
    
    
                        &:nth-last-child(1) {

                        }
    
    
    
                    }
                }
            }
        }
    }

    .clickedNavbar {


    font-family: 'Lato';
    height: 350px;
    padding: 0 3.5rem;
    background-color: $nav-bg;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
        cursor: pointer;
    }

    .links {
        ul {
            display: flex;
            gap: 2rem;
            flex-direction: column;


            li {
                list-style-type: none;
                transition: filter .35s ease;
                &:hover {
                    filter: brightness(1.2);
                }

                .activeLinkUnderline {
                    color: $nxtech-light;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        left: 0;
                        bottom: -2px;

                        background-color: $nxtech-dark;
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 1.2rem;
                }

                .logg_inn_button {
                    width: 100px;
                    height: 30px;
                    background-color: $nxtech-dark;
                    border-radius: 1.5rem;
                    outline: none;
                    border: 0;
                    color: $primary_white;
            
                    font-size: 1rem;
                    padding: 2px;
                    cursor: pointer;
                    transition: filter .35s ease;



                    &:hover {
                        filter: brightness(1.2);
                    }

                    &:active {
                        filter: brightness(0.65);
                    }
                }
            }
        }
    }

    .dropdownclicked {
        position: absolute;
        background-color: $nav-bg;
        left: 0;
        top: 0;
        width: 100%;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        .links {
            width: 90%;


            ul {

                display: flex;
                justify-content: space-evenly;
                align-items: center;
                align-self: center;


                svg {
                    color: $nxtech-normal;
                }

                li {
                    cursor: pointer;


                    &:nth-last-child(1) {
                        margin: 0;
                        width: 30px;
                        height: 30px;
                    }



                }
            }
        }
    }
    }
    
}


@media screen and (max-width: 349px)  {
    .navbar {
        padding: 0rem 1.1rem;
    }
}



@media screen and (min-width: 2000px) {
    .navbar {
        height: 160px;

        .logo {
            a {
                img {
                    width: 400px!important;
                }
            }
        }

        .links {


            ul {

                gap: 4rem;
                li {
                    a {
                        font-size: 2.4rem;
                    }

                    .logg_inn_button {
                        width: 200px;
                        height: 60px;

                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}