@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Open+Sans:wght@300;400&display=swap");
.navbar {
  font-family: 'Lato';
  height: 80px;
  padding: 0 3.5rem;
  background-color: #242424;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

.navbar .logo {
  cursor: pointer;
}

.navbar .dropdown {
  cursor: pointer;
}

.navbar .links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
}

.navbar .links ul li {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: -webkit-filter .35s ease;
  transition: -webkit-filter .35s ease;
  transition: filter .35s ease;
  transition: filter .35s ease, -webkit-filter .35s ease;
}

.navbar .links ul li:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.navbar .links ul li .activeLinkUnderline {
  color: #1ed9e9;
  position: relative;
}

.navbar .links ul li .activeLinkUnderline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -2px;
  background-color: #1392a1;
}

.navbar .links ul li a {
  text-decoration: none;
  font-size: 1.2rem;
}

.navbar .links ul li .logg_inn_button {
  width: 100px;
  height: 30px;
  background-color: #1392a1;
  border-radius: 1.5rem;
  outline: none;
  border: 0;
  color: #ffffff;
  font-size: 1rem;
  padding: 2px;
  cursor: pointer;
  -webkit-transition: -webkit-filter .35s ease;
  transition: -webkit-filter .35s ease;
  transition: filter .35s ease;
  transition: filter .35s ease, -webkit-filter .35s ease;
}

.navbar .links ul li .logg_inn_button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.navbar .links ul li .logg_inn_button:active {
  -webkit-filter: brightness(0.65);
          filter: brightness(0.65);
}

.navbar .dropdownclicked {
  position: absolute;
  background-color: #242424;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar .dropdownclicked .links {
  width: 90%;
}

.navbar .dropdownclicked .links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
}

.navbar .dropdownclicked .links ul svg {
  color: #89e7ef;
}

.navbar .dropdownclicked .links ul li {
  cursor: pointer;
  -webkit-transition: -webkit-filter .35s ease;
  transition: -webkit-filter .35s ease;
  transition: filter .35s ease;
  transition: filter .35s ease, -webkit-filter .35s ease;
}

.navbar .dropdownclicked .links ul li:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.navbar .dropdownclicked .links ul li:nth-last-child(1) {
  margin: 0;
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 950px) {
  .navbar {
    padding: 0 1rem;
  }
  .navbar .logo {
    cursor: pointer;
  }
  .navbar .links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .navbar .links ul li {
    -webkit-transition: -webkit-filter .35s ease;
    transition: -webkit-filter .35s ease;
    transition: filter .35s ease;
    transition: filter .35s ease, -webkit-filter .35s ease;
  }
  .navbar .links ul li:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  .navbar .dropdownclicked .links ul li {
    -webkit-transition: -webkit-filter .35s ease;
    transition: -webkit-filter .35s ease;
    transition: filter .35s ease;
    transition: filter .35s ease, -webkit-filter .35s ease;
  }
  .navbar .dropdownclicked .links ul li:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  .clickedNavbar {
    font-family: 'Lato';
    height: 350px;
    padding: 0 3.5rem;
    background-color: #242424;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  .clickedNavbar .logo {
    cursor: pointer;
  }
  .clickedNavbar .links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .clickedNavbar .links ul li {
    list-style-type: none;
    -webkit-transition: -webkit-filter .35s ease;
    transition: -webkit-filter .35s ease;
    transition: filter .35s ease;
    transition: filter .35s ease, -webkit-filter .35s ease;
  }
  .clickedNavbar .links ul li:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  .clickedNavbar .links ul li .activeLinkUnderline {
    color: #1ed9e9;
    position: relative;
  }
  .clickedNavbar .links ul li .activeLinkUnderline::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -2px;
    background-color: #1392a1;
  }
  .clickedNavbar .links ul li a {
    text-decoration: none;
    font-size: 1.2rem;
  }
  .clickedNavbar .links ul li .logg_inn_button {
    width: 100px;
    height: 30px;
    background-color: #1392a1;
    border-radius: 1.5rem;
    outline: none;
    border: 0;
    color: #ffffff;
    font-size: 1rem;
    padding: 2px;
    cursor: pointer;
    -webkit-transition: -webkit-filter .35s ease;
    transition: -webkit-filter .35s ease;
    transition: filter .35s ease;
    transition: filter .35s ease, -webkit-filter .35s ease;
  }
  .clickedNavbar .links ul li .logg_inn_button:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  .clickedNavbar .links ul li .logg_inn_button:active {
    -webkit-filter: brightness(0.65);
            filter: brightness(0.65);
  }
  .clickedNavbar .dropdownclicked {
    position: absolute;
    background-color: #242424;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .clickedNavbar .dropdownclicked .links {
    width: 90%;
  }
  .clickedNavbar .dropdownclicked .links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: center;
        align-self: center;
  }
  .clickedNavbar .dropdownclicked .links ul svg {
    color: #89e7ef;
  }
  .clickedNavbar .dropdownclicked .links ul li {
    cursor: pointer;
  }
  .clickedNavbar .dropdownclicked .links ul li:nth-last-child(1) {
    margin: 0;
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 349px) {
  .navbar {
    padding: 0rem 1.1rem;
  }
}

@media screen and (min-width: 2000px) {
  .navbar {
    height: 160px;
  }
  .navbar .logo a img {
    width: 400px !important;
  }
  .navbar .links ul {
    gap: 4rem;
  }
  .navbar .links ul li a {
    font-size: 2.4rem;
  }
  .navbar .links ul li .logg_inn_button {
    width: 200px;
    height: 60px;
    font-size: 2.2rem;
  }
}

.omoss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2.5rem;
  width: 100%;
}

.omoss .cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 4rem;
  border-radius: 1rem;
  padding: 1rem;
}

.omoss .cards .card {
  width: 250px;
  background-color: grey;
  border-radius: 1rem;
  overflow: hidden;
  height: 335px;
  cursor: pointer;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
  -webkit-transition: -webkit-filter .25s ease;
  transition: -webkit-filter .25s ease;
  transition: filter .25s ease;
  transition: filter .25s ease, -webkit-filter .25s ease;
}

.omoss .cards .card .card-info {
  padding: 1rem;
}

.omoss .cards .card .profilePicture {
  width: 250px;
}

.omoss .cards .card:hover {
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

@media screen and (max-width: 950px) and (min-width: 751px) {
  .omoss .cards .card:nth-child(3) {
    width: calc(500px + 4rem);
    height: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .omoss .cards .card:nth-child(3) .profilePicture {
    aspect-ratio: auto;
    height: 250px;
    width: auto;
  }
  .omoss .cards .card:nth-child(3) h2 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 750px) {
  .omoss .cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media screen and (min-width: 1101px) {
  .person-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 2rem;
    background-color: #ffffff;
    color: #000000;
  }
  .person-side .closeButton {
    width: 50px;
    height: 50px;
    color: black;
    margin-left: 1rem;
    cursor: pointer;
  }
  .person-side img {
    height: 100%;
    width: 300px;
    z-index: 10;
    border-bottom-right-radius: 2rem;
  }
  .person-side .person-details {
    padding: 2rem 3.5rem 3rem 1.5rem;
    position: relative;
    z-index: 5;
    width: 800px;
  }
  .person-side .person-details::before {
    content: "";
    background-color: #1393a13f;
    border-radius: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -50px;
    z-index: -5;
  }
  .person-side .person-details h1, .person-side .person-details h3 {
    margin-top: 2.5rem;
  }
  .person-side .person-details h1 {
    font-size: 3rem;
    font-weight: 300;
  }
  .person-side .person-details h1:nth-child(1) {
    margin-top: 0;
  }
  .person-side .person-details p {
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 751px) and (max-width: 1100px) {
  .person-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 2rem;
    background-color: #ffffff;
    color: #000000;
  }
  .person-side .closeButton {
    width: 50px;
    height: 50px;
    color: black;
    margin-left: 1rem;
    cursor: pointer;
  }
  .person-side img {
    height: 100%;
    width: 300px;
    z-index: 10;
    border-bottom-right-radius: 2rem;
  }
  .person-side .person-details {
    padding: 2rem 3.5rem 3rem 1.5rem;
    position: relative;
    z-index: 5;
  }
  .person-side .person-details::before {
    content: "";
    background-color: #1393a13f;
    border-radius: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -50px;
    z-index: -5;
  }
  .person-side .person-details h1, .person-side .person-details h3 {
    margin-top: 2.5rem;
  }
  .person-side .person-details h1 {
    font-size: 3rem;
    font-weight: 300;
  }
  .person-side .person-details h1:nth-child(1) {
    margin-top: 0;
  }
  .person-side .person-details p {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 750px) {
  .person-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    color: #000000;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    padding: 0 1.2rem;
  }
  .person-side .closeButton {
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 10;
    color: black;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .person-side .person-details {
    padding: 2rem 3.5rem 3rem 1.5rem;
    position: relative;
    z-index: 5;
  }
  .person-side .person-details::before {
    content: "";
    background-color: #1393a13f;
    border-radius: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .person-side .person-details h1, .person-side .person-details h3 {
    margin-top: 2.5rem;
  }
  .person-side .person-details h1 {
    font-size: 3rem;
    font-weight: 300;
  }
  .person-side .person-details h1:nth-child(1) {
    margin-top: 0;
  }
  .person-side .person-details p {
    margin-top: 0.5rem;
  }
}

.home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2rem;
  position: relative;
  font-family: 'Lato';
}

.home .siste_registrering_slette_farge {
  color: #1ed9e9;
}

.home .diagram_siste_30_dager {
  margin-top: 2.5rem;
  width: 750px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home .diagram_siste_30_dager h2 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  position: relative;
  margin-bottom: 1rem;
  position: relative;
}

.home .diagram_siste_30_dager h2::after {
  content: "";
  position: absolute;
  height: 2px;
  bottom: -4px;
  left: -1rem;
  right: -1rem;
  z-index: -10;
  background-color: #1ba9b8;
}

.home .info_button {
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 62.5%;
  -webkit-transform: translateY(-62.5%);
          transform: translateY(-62.5%);
  right: -2rem;
  color: #ffffff;
  cursor: pointer;
}

.home .info_button:hover {
  color: #da3733;
}

.home .info_button:active, .home .info_button:focus {
  color: #6c2b2b;
}

.home .Sammenligning_info {
  position: absolute;
  width: 12rem;
  background-color: #ffffff;
  color: #000000;
  top: 10px;
  left: 2rem;
  border-radius: 0.6rem;
  border: 2px solid #000000;
  padding: 0.4rem;
  text-align: start;
  font-size: 0.967rem;
  z-index: 100;
}

.home .diagram_doughnut {
  margin-top: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home .diagram_doughnut .totalt_siden_2023 {
  position: relative;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.home .diagram_doughnut .totalt_siden_2023::after {
  content: "";
  position: absolute;
  height: 2px;
  bottom: -4px;
  left: -1rem;
  right: -1rem;
  z-index: -10;
  background-color: #1ba9b8;
}

.home .diagram_doughnut canvas {
  width: 550px;
}

.home .show_datetime {
  position: absolute;
  top: 0;
  left: 1rem;
  margin-left: 1rem;
  font-style: italic;
  z-index: 100;
}

.home .sorter_listevisning_container .sorteringsKnapp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: auto;
  height: 40px;
  padding: 1rem;
  background-color: #1392a1;
  border-radius: 0.25rem;
  -webkit-transition: -webkit-filter .23s ease;
  transition: -webkit-filter .23s ease;
  transition: filter .23s ease;
  transition: filter .23s ease, -webkit-filter .23s ease;
}

.home .sorter_listevisning_container .sorteringsKnapp:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  cursor: pointer;
}

.home .sorter_listevisning_container .sorteringsKnapp:active {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
  cursor: pointer;
}

.home .sorter_listevisning_container .sorteringsValg {
  height: 40px;
}

.home .sorter_listevisning_container .sorteringsValg ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
}

.home .sorter_listevisning_container .sorteringsValg ul li {
  list-style-type: none;
  position: relative;
  font-size: 1.2rem;
}

.home .sorter_listevisning_container .sorteringsValg ul li a {
  text-decoration: none;
}

.home .sorter_listevisning_container .sorteringsValg ul li:hover {
  cursor: pointer;
  color: #1ed9e9;
}

.home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg {
  position: relative;
}

.home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -5px;
  background-color: #1392a1;
}

.home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg .sortert {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #ffffff;
  font-size: 1rem;
  margin: 0;
  bottom: -1.5rem;
}

.home .sorter_listevisning_container .sorteringsValg ul .close_sorted_options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home .målinger {
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  gap: 5rem;
}

.home .målinger .oversikt_vekt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home .målinger .oversikt_vekt h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.home .målinger .oversikt_vekt ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.home .målinger .oversikt_vekt ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-width: 220px;
  border-bottom: 1px solid white;
  padding: 0.5rem;
  gap: 1.21rem;
}

.home .målinger .oversikt_vekt ul li:nth-last-child(1) {
  border-bottom: 0;
}

.home .ekstra_features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 4rem;
  gap: 0.75rem;
}

.home .ekstra_features .button_rapporteringer {
  width: 160px;
  height: 40px;
  font-size: 1.2rem;
  border-radius: 1.2rem;
  background-color: #0D97A4;
  color: #ffffff;
  outline: none;
  border: 1px solid white;
  cursor: pointer;
}

.home .ekstra_features h2 {
  font-size: 2rem;
  position: relative;
}

.home .ekstra_features h2::after {
  content: "";
  position: absolute;
  width: 120%;
  height: 2px;
  bottom: 0px;
  left: -10%;
  background-color: #1ba9b8;
}

.home .ekstra_features .siste_registrerte_feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
}

.home .ekstra_features .siste_registrerte_feature li {
  font-size: 1.5rem;
}

.home .ekstra_features .siste_registrerte_feature .slett_meg_knapp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home .ekstra_features .siste_registrerte_feature .slett_meg_knapp button {
  width: 5rem;
  height: 30px;
  font-size: 1rem;
  border: 1px solid #6c2b2b;
  color: #f75249;
  cursor: pointer;
  background-color: #242424;
  -webkit-transition: -webkit-filter .2s ease;
  transition: -webkit-filter .2s ease;
  transition: filter .2s ease;
  transition: filter .2s ease, -webkit-filter .2s ease;
  -webkit-transition: background-Color .19s ease-out;
  transition: background-Color .19s ease-out;
}

.home .ekstra_features .siste_registrerte_feature .slett_meg_knapp button:hover {
  background-color: #da3733;
  color: #ffffff;
  border: 1px solid #f75249;
}

.home .ekstra_features .siste_registrerte_feature .slett_meg_knapp button:active {
  -webkit-filter: brightness(0.65);
          filter: brightness(0.65);
}

.home .ekstra_features .siste_registrerte_feature table {
  border: 2px solid #ffffff;
}

.home .ekstra_features .siste_registrerte_feature table tr {
  height: 2.5rem;
}

.home .ekstra_features .siste_registrerte_feature table tr th {
  margin: 0;
  border-bottom: 2px solid #ffffff;
}

.home .ekstra_features .siste_registrerte_feature table tr td {
  padding: 0 1rem;
}

@media screen and (max-width: 750px) and (min-width: 350px) {
  .home {
    margin-top: 4rem;
  }
  .home .show_datetime {
    top: -3rem;
  }
  .home .diagram_siste_30_dager {
    margin-top: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 375px;
  }
  .home .diagram_doughnut canvas {
    width: 350px;
  }
  .home .Sammenligning_info {
    position: absolute;
    width: 12rem;
    background-color: #ffffff;
    color: #000000;
    top: 2.2rem;
    left: -11rem;
    border-radius: 0.6rem;
    border: 2px solid #000000;
    padding: 0.4rem;
    text-align: start;
    font-size: 0.967rem;
    z-index: 100;
  }
  .home .målinger {
    margin-top: 3rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .home .målinger .oversikt_vekt ul li {
    width: 250px;
  }
  .home .målinger .oversikt_vekt ul li h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 349px) {
  .home {
    margin-top: 4rem;
  }
  .home .show_datetime {
    top: -3rem;
  }
  .home .diagram_siste_30_dager {
    margin-top: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 280px;
  }
  .home .diagram_siste_30_dager h2 {
    font-size: 1.8rem;
  }
  .home .diagram_siste_30_dager canvas {
    height: 400px;
  }
  .home .diagram_doughnut canvas {
    width: 250px;
  }
  .home .Sammenligning_info {
    position: absolute;
    width: 12rem;
    background-color: #ffffff;
    color: #000000;
    top: 2.2rem;
    left: -11rem;
    border-radius: 0.6rem;
    border: 2px solid #000000;
    padding: 0.4rem;
    text-align: start;
    font-size: 0.967rem;
    z-index: 100;
  }
  .home .ekstra_features h2 {
    font-size: 1.5rem;
  }
  .home .sorter_listevisning_container {
    height: 100%;
  }
  .home .sorter_listevisning_container .sorteringsValg {
    height: 180px;
  }
  .home .sorter_listevisning_container .sorteringsValg ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 180px;
    gap: 1.5rem;
  }
  .home .sorter_listevisning_container .sorteringsValg ul li {
    list-style-type: none;
    position: relative;
    font-size: 1.2rem;
  }
  .home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg {
    height: 180px;
  }
  .home .sorter_listevisning_container .sorteringsKnapp {
    height: 40px;
  }
  .home .målinger {
    margin-top: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .home .målinger .oversikt_vekt h2 {
    font-size: 1.8rem;
  }
  .home .målinger .oversikt_vekt ul li {
    width: 250px;
  }
  .home .målinger .oversikt_vekt ul li h3 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 2000px) {
  .home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 2rem;
    position: relative;
    font-family: 'Lato';
  }
  .home .show_datetime {
    font-size: 2.4rem;
    margin-left: 4rem;
  }
  .home .diagram_siste_30_dager {
    margin-top: 2.5rem;
    min-width: 85%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .home .diagram_siste_30_dager h2 {
    font-size: 5rem;
  }
  .home .info_button {
    background-color: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 62.5%;
    -webkit-transform: translateY(-62.5%);
            transform: translateY(-62.5%);
    right: -5.5rem;
    color: #ffffff;
    cursor: pointer;
  }
  .home .info_button:hover {
    color: #da3733;
  }
  .home .info_button:active, .home .info_button:focus {
    color: #6c2b2b;
  }
  .home .info_button svg {
    font-size: 5rem;
    width: 65px;
    height: 65px;
  }
  .home .Sammenligning_info {
    position: absolute;
    width: 30rem;
    background-color: #ffffff;
    color: #000000;
    top: 0px;
    left: 5.5rem;
    border-radius: 0.6rem;
    border: 2px solid #000000;
    padding: 0.4rem;
    text-align: start;
    font-size: 2rem;
    z-index: 100;
  }
  .home .diagram_doughnut {
    margin-top: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .home .diagram_doughnut .totalt_siden_2023 {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 5rem;
  }
  .home .diagram_doughnut .totalt_siden_2023::after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -4px;
    left: -1rem;
    right: -1rem;
    background-color: #1ba9b8;
  }
  .home .diagram_doughnut canvas {
    width: 1000px;
  }
  .home .show_datetime {
    position: absolute;
    top: 0;
    left: 1rem;
    font-style: italic;
    z-index: 100;
  }
  .home .sorter_listevisning_container .sorteringsKnapp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 300px;
    height: 90px;
    padding: 1rem;
    background-color: #1392a1;
    border-radius: 0.25rem;
    -webkit-transition: -webkit-filter .23s ease;
    transition: -webkit-filter .23s ease;
    transition: filter .23s ease;
    transition: filter .23s ease, -webkit-filter .23s ease;
    font-size: 2rem;
  }
  .home .sorter_listevisning_container .sorteringsKnapp:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    cursor: pointer;
  }
  .home .sorter_listevisning_container .sorteringsKnapp:active {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85);
    cursor: pointer;
  }
  .home .sorter_listevisning_container .sorteringsValg {
    height: 90px;
  }
  .home .sorter_listevisning_container .sorteringsValg ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5rem;
  }
  .home .sorter_listevisning_container .sorteringsValg ul li {
    list-style-type: none;
    position: relative;
    font-size: 3rem;
  }
  .home .sorter_listevisning_container .sorteringsValg ul li a {
    text-decoration: none;
  }
  .home .sorter_listevisning_container .sorteringsValg ul li:hover {
    cursor: pointer;
    color: #1ed9e9;
  }
  .home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg {
    position: relative;
  }
  .home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -5px;
    background-color: #1392a1;
  }
  .home .sorter_listevisning_container .sorteringsValg ul .activeSorteringsValg .sortert {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    color: #ffffff;
    font-size: 1rem;
    margin: 0;
    bottom: -1.5rem;
  }
  .home .sorter_listevisning_container .sorteringsValg ul .close_sorted_options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .home .målinger {
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    gap: 5rem;
    width: 90%;
  }
  .home .målinger .oversikt_vekt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 45%;
  }
  .home .målinger .oversikt_vekt h2 {
    font-size: 5rem;
    margin-bottom: 3rem;
  }
  .home .målinger .oversikt_vekt ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 3px solid white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    min-width: 100%;
  }
  .home .målinger .oversikt_vekt ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-width: 100%;
    border-bottom: 3px solid white;
    padding: 0.5rem;
    gap: 1.5rem;
    height: 90px;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .home .målinger .oversikt_vekt ul li:nth-last-child(1) {
    border-bottom: 0;
    margin-bottom: 0rem;
  }
  .home .ekstra_features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 4rem;
    gap: 0.75rem;
  }
  .home .ekstra_features h2 {
    font-size: 5rem;
    position: relative;
  }
  .home .ekstra_features h2::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 2px;
    bottom: 0px;
    left: -10%;
    background-color: #1ba9b8;
  }
  .home .ekstra_features h3 {
    font-size: 2.5rem;
  }
  .home .ekstra_features .button_rapporteringer {
    width: 300px;
    height: 50px;
    border-radius: 2rem;
    font-size: 2rem;
  }
  .home .ekstra_features .siste_registrerte_feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 4rem;
  }
  .home .ekstra_features .siste_registrerte_feature table tr th {
    font-size: 3rem;
    padding: 0.5rem 2rem;
  }
  .home .ekstra_features .siste_registrerte_feature table tr td {
    font-size: 3rem;
    padding: 0.5rem 2rem;
  }
  .home .ekstra_features .siste_registrerte_feature .slett_meg_knapp button {
    width: 10rem;
    height: 50px;
    font-size: 2rem;
    border: 1px solid #6c2b2b;
    color: #f75249;
    cursor: pointer;
    background-color: #242424;
    -webkit-transition: -webkit-filter .2s ease;
    transition: -webkit-filter .2s ease;
    transition: filter .2s ease;
    transition: filter .2s ease, -webkit-filter .2s ease;
    -webkit-transition: background-Color .19s ease-out;
    transition: background-Color .19s ease-out;
  }
  .home .ekstra_features .siste_registrerte_feature .slett_meg_knapp button:hover {
    background-color: #da3733;
    color: #ffffff;
    border: 1px solid #f75249;
  }
  .home .ekstra_features .siste_registrerte_feature .slett_meg_knapp button:active {
    -webkit-filter: brightness(0.65);
            filter: brightness(0.65);
  }
}

.logg_inn_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Lato";
}

.logg_inn_container .logg_inn_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.logg_inn_container .logg_inn_form label {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 2rem;
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.logg_inn_container .logg_inn_form input {
  font-size: 1.2rem;
  height: 50px;
  min-width: 250px;
  padding: 1px 1px 1px 1rem;
  outline: none;
  border-radius: 1.5rem;
  position: relative;
  background-color: white;
}

.logg_inn_container .logg_inn_form input:focus {
  border: 1px solid #1392a1;
  -webkit-box-shadow: 0px 2px 7px #1ed9e9;
          box-shadow: 0px 2px 7px #1ed9e9;
}

.logg_inn_container .logg_inn_form button {
  margin-top: 2rem;
  width: 100px;
  height: 45px;
  border-radius: 0.3rem;
  cursor: pointer;
}

.logg_inn_container .logg_inn_form button:hover {
  background-color: #1ed9e9;
}

.logg_inn_container .logg_inn_form button:active {
  background-color: #1392a1;
}

@media screen and (min-width: 2000px) {
  .logg_inn_container form label {
    font-size: 5rem;
  }
  .logg_inn_container form input {
    font-size: 4rem;
    height: 100px;
    min-width: 500px;
    padding: 2px 2px 2px 2rem;
    outline: none;
    border-radius: 1.5rem;
    position: relative;
    background-color: white;
  }
  .logg_inn_container form input:focus {
    border: 3px solid #1392a1;
    -webkit-box-shadow: 0px 2px 7px #1ed9e9;
            box-shadow: 0px 2px 7px #1ed9e9;
  }
  .logg_inn_container form button {
    margin-top: 2rem;
    width: 200px;
    height: 90px;
    border-radius: 0.3rem;
    font-size: 2.5rem;
    cursor: pointer;
  }
  .logg_inn_container form button:hover {
    background-color: #1ed9e9;
  }
  .logg_inn_container form button:active {
    background-color: #1392a1;
  }
}

.rapport_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
}

.rapport_container h1 {
  margin-top: 2rem;
}

.rapport_container .button_rapporteringer {
  width: 160px;
  height: 40px;
  font-size: 1.2rem;
  border-radius: 1.2rem;
  background-color: #0D97A4;
  color: #ffffff;
  outline: none;
  border: 1px solid white;
  cursor: pointer;
}

.rapport_container .buttons {
  margin-top: calc(1.5rem - 1rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.5rem;
}

.rapport_container .buttons button {
  min-width: 265px;
  height: 45px;
  cursor: pointer;
  font-size: 1.2rem;
  position: relative;
  background-color: #0D97A4;
  color: #ffffff;
  outline: none;
  border-radius: 0.3rem;
  border: 1px solid #ffffff;
  -webkit-transition: -webkit-filter .245s ease;
  transition: -webkit-filter .245s ease;
  transition: filter .245s ease;
  transition: filter .245s ease, -webkit-filter .245s ease;
}

.rapport_container .buttons button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.rapport_container .buttons button:active {
  -webkit-filter: brightness(0.65);
          filter: brightness(0.65);
  border: solid 1px black;
}

.rapport_container .download_PDF {
  width: 120px;
  height: 2.5rem;
  background-color: transparent;
  outline: none;
  font-size: 1.2rem;
  color: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
  -webkit-transition: -webkit-filter .25s ease;
  transition: -webkit-filter .25s ease;
  transition: filter .25s ease;
  transition: filter .25s ease, -webkit-filter .25s ease;
}

.rapport_container .download_PDF:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #0D97A4;
}

.rapport_container .download_PDF:active {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
  border: solid 1px black;
  background-color: #0D97A4;
}

@media screen and (min-width: 2000px) {
  .rapport_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 1.5rem;
  }
  .rapport_container h1 {
    margin-top: 3rem;
    font-size: 5rem;
  }
  .rapport_container h2 {
    font-size: 5rem;
  }
  .rapport_container .button_rapporteringer {
    width: 320px;
    height: 75px;
    font-size: 2.5rem;
    border-radius: 2rem;
    background-color: #0D97A4;
    color: #ffffff;
    outline: none;
    border: 1px solid white;
    cursor: pointer;
  }
  .rapport_container .buttons {
    margin-top: calc(1.5rem - 1rem);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1.5rem;
  }
  .rapport_container .buttons button {
    min-width: 530px;
    height: 90px;
    cursor: pointer;
    font-size: 2.5rem;
    position: relative;
    background-color: #0D97A4;
    color: #ffffff;
    outline: none;
    border-radius: 0.3rem;
    border: 1px solid #ffffff;
    -webkit-transition: -webkit-filter .245s ease;
    transition: -webkit-filter .245s ease;
    transition: filter .245s ease;
    transition: filter .245s ease, -webkit-filter .245s ease;
  }
  .rapport_container .buttons button:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  .rapport_container .buttons button:active {
    -webkit-filter: brightness(0.65);
            filter: brightness(0.65);
    border: solid 1px black;
  }
  .rapport_container .download_PDF {
    width: 240px;
    height: 5rem;
    background-color: transparent;
    outline: none;
    font-size: 2.5rem;
    color: #ffffff;
    border: 1px solid #ffffff;
    cursor: pointer;
    -webkit-transition: -webkit-filter .25s ease;
    transition: -webkit-filter .25s ease;
    transition: filter .25s ease;
    transition: filter .25s ease, -webkit-filter .25s ease;
  }
  .rapport_container .download_PDF:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    background-color: #0D97A4;
  }
  .rapport_container .download_PDF:active {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85);
    border: solid 1px black;
    background-color: #0D97A4;
  }
}

.page_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.page_wrapper header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2.5rem;
  gap: 0.6rem;
}

.page_wrapper header .prosjektlogo {
  width: 250px;
}

.page_wrapper header h1 {
  font-size: 2.2rem;
}

.page_wrapper header h4 {
  color: grey;
}

.page_wrapper main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.page_wrapper main article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 750px;
}

.page_wrapper main article h2 {
  margin-top: 2.5rem;
}

.page_wrapper main article p {
  margin-top: 1rem;
}

.page_wrapper main article p:nth-child(2) {
  margin-top: 0;
}

.rapporteringer_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 3rem;
}

.rapporteringer_container .authenticated_show_data_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  min-height: 100vh;
}

.rapporteringer_container .authenticated_show_data_wrapper h1 {
  margin-bottom: 2rem;
}

.rapporteringer_container .authenticated_show_data_wrapper table {
  border: 2px solid #1392a1;
  width: 100%;
  position: relative;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering {
  cursor: pointer;
  background-color: #282c34;
  -webkit-transition: -webkit-filter .235s ease;
  transition: -webkit-filter .235s ease;
  transition: filter .235s ease;
  transition: filter .235s ease, -webkit-filter .235s ease;
  position: relative;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering div svg {
  position: absolute;
  left: calc(50% + 2rem);
  -webkit-transform: translateX(calc(-50% + 2rem));
          transform: translateX(calc(-50% + 2rem));
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id {
  cursor: pointer;
  background-color: #282c34;
  -webkit-transition: -webkit-filter .235s ease;
  transition: -webkit-filter .235s ease;
  transition: filter .235s ease;
  transition: filter .235s ease, -webkit-filter .235s ease;
  position: relative;
  width: 100px;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id div svg {
  position: absolute;
  left: calc(50% + 1rem);
  -webkit-transform: translateX(calc(-50% + 1rem));
          transform: translateX(calc(-50% + 1rem));
}

.rapporteringer_container .authenticated_show_data_wrapper table tr th {
  border-bottom: 1px solid #1392a1;
  height: 3.5rem;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr td {
  text-align: center;
  height: 3.5rem;
  border-bottom: 2px solid white;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr button {
  width: 5rem;
  height: 30px;
  font-size: 1rem;
  border: 1px solid #6c2b2b;
  color: #f75249;
  cursor: pointer;
  background-color: #242424;
  -webkit-transition: -webkit-filter .2s ease;
  transition: -webkit-filter .2s ease;
  transition: filter .2s ease;
  transition: filter .2s ease, -webkit-filter .2s ease;
  -webkit-transition: background-Color .19s ease-out;
  transition: background-Color .19s ease-out;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr button:hover {
  background-color: #da3733;
  color: #ffffff;
  border: 1px solid #f75249;
}

.rapporteringer_container .authenticated_show_data_wrapper table tr button:active {
  -webkit-filter: brightness(0.65);
          filter: brightness(0.65);
}

@media screen and (min-width: 1300px) {
  .rapporteringer_container .authenticated_show_data_wrapper {
    width: 75%;
  }
}

@media screen and (max-width: 800px) {
  .rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering {
    height: 40px;
  }
  .rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    position: relative;
  }
  .rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering div svg {
    position: initial;
    left: initial;
    -webkit-transform: initial;
            transform: initial;
  }
  .rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id {
    height: 40px;
  }
  .rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    position: relative;
  }
  .rapporteringer_container .authenticated_show_data_wrapper table tr .table_header_sortering_id div svg {
    position: initial;
    left: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  background-color: #282c34;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

body p {
  font-family: 'Open Sans';
}

body h1, body h2, body h3, body h4, body h5, body h6 {
  font-family: 'Lato';
  font-weight: 700;
}

body .App {
  width: 100vw;
  height: 100vh;
  color: white;
}

body .App a {
  color: white;
}

body .App .layout {
  width: 100%;
}

body .App .noside_404 {
  min-width: 100%;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .App .noside_404 h1 {
  margin-top: 5rem;
}

body .App .noside_404 p {
  margin-top: 2rem;
}

body .App .noside_404 p a {
  cursor: pointer;
  color: #1ed9e9;
  font-size: 2rem;
}

@media screen and (min-width: 1401px) {
  body .App {
    min-width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  body .App {
    width: 100vw;
  }
}
