@media screen and (min-width: 1101px) {
    

    .person-side {
        display: flex;
        justify-content: center;
        padding: 2rem;
        background-color: $primary-white;
        color: $primary-black;
    
        .closeButton {
            width: 50px;
            height: 50px;
            color: black;
            margin-left: 1rem;

    
            cursor: pointer;
        }
    
        img {
            height: 100%;
            width: 300px;
            z-index: 10;
    
            border-bottom-right-radius: 2rem;
        }
    
        .person-details {
            padding: 2rem 3.5rem 3rem 1.5rem;
            position: relative;
            z-index: 5;
            width: 800px;
    
            &::before {
                content: "";
                background-color: $nxtech-dark-lowOpacity;
                border-radius: 2rem;
                position: absolute;
    
                top: 0;
                right: 0;
                bottom: 0;
                left: -50px;
    
                z-index: -5;
            }
    
    
    
    
            h1, h3 {
                margin-top: 2.5rem;
    
            }
    
            h1 {
                font-size: 3rem;
                font-weight: 300;
    
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
    
            h3 {
    
            }
    
            p {
                margin-top: 0.5rem;
            }
        }
    }
    
    }




@media screen and (min-width: 751px) and (max-width: 1100px) {
    

.person-side {
    display: flex;
    justify-content: center;

    padding: 2rem;
    background-color: $primary-white;
    color: $primary-black;

    .closeButton {
        width: 50px;
        height: 50px;
        color: black;
        margin-left: 1rem;
        
        cursor: pointer;
    }

    img {
        height: 100%;
        width: 300px;
        z-index: 10;

        border-bottom-right-radius: 2rem;
    }

    .person-details {
        padding: 2rem 3.5rem 3rem 1.5rem;
        position: relative;
        z-index: 5;


        &::before {
            content: "";
            background-color: $nxtech-dark-lowOpacity;
            border-radius: 2rem;
            position: absolute;

            top: 0;
            right: 0;
            bottom: 0;
            left: -50px;

            z-index: -5;
        }




        h1, h3 {
            margin-top: 2.5rem;

        }

        h1 {
            font-size: 3rem;
            font-weight: 300;

            &:nth-child(1) {
                margin-top: 0;
            }
        }

        h3 {

        }

        p {
            margin-top: 0.5rem;
        }
    }
}

}

@media screen and (max-width: 750px) {

    .person-side {

        display: flex;
        background-color: $primary-white;
        color: $primary-black;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: relative;
        padding: 0 1.2rem;
    
        .closeButton {
            width: 60px;
            height: 60px;
    
            cursor: pointer;
            z-index: 10;
            color: black;
            position: absolute;
            top: 2rem;
            right: 2rem;
        }
    
        img {
        }
    
        .person-details {
            padding: 2rem 3.5rem 3rem 1.5rem;
            position: relative;
            z-index: 5;
    
    
            &::before {
                content: "";
                background-color: $nxtech-dark-lowOpacity;
                border-radius: 2rem;
                position: absolute;
    
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
    
            }
    
    
    
    
            h1, h3 {
                margin-top: 2.5rem;
    
            }
    
            h1 {
                font-size: 3rem;
                font-weight: 300;
    
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
    
            h3 {
    
            }
    
            p {
                margin-top: 0.5rem;
            }
        }
    }
}

