.rapporteringer_container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .authenticated_show_data_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        width: 90%;
        min-height: 100vh;

        h1 {
            margin-bottom: 2rem;
        }

        table {

            border: 2px solid $nxtech-dark;
            width: 100%;
            position: relative;


            tr {

                .table_header_sortering {
                    cursor: pointer;
                    background-color: $page_background;
                    transition: filter .235s ease;
                    position: relative;

                    &:hover {
                        filter: brightness(1.2);
                    }


                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        position: relative;

                        svg {
                            position: absolute;
                            left: calc(50% + 2rem);
                            transform: translateX(calc(-50% + 2rem));
                            // margin: 0 1.5rem;
                        }
                    }
                }

                .table_header_sortering_id {
                    cursor: pointer;
                    background-color: $page_background;
                    transition: filter .235s ease;
                    position: relative;
                    width: 100px;

                    &:hover {
                        filter: brightness(1.2);
                    }

                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        position: relative;

                        svg {
                            position: absolute;
                            left: calc(50% + 1rem);
                            transform: translateX(calc(-50% + 1rem));
                            // margin: 0 1.5rem;
                        }
                    }
                }

                th {
                    border-bottom: 1px solid  $nxtech-dark;
                    height: 3.5rem;
                }
    
    
                td {
                    text-align: center;
                    height: 3.5rem;
                    border-bottom: 2px solid white;
    
                }

                button {
                    width: 5rem;
                    height: 30px;
                    font-size: 1rem;
                    border: 1px solid $nxtech_slett_meg_rod_border;
                    color: $nxtech_slett_meg_rod_tekst;

                    cursor: pointer;
                    background-color: $nav-bg;
                    transition: filter .2s ease;
                    transition: background-Color .19s ease-out;

                    &:hover {
                    background-color: $nxtech_slett_meg_rod_hover;
                    color: $primary-white;
                    border: 1px solid $nxtech_slett_meg_rod_tekst
                    }

                    &:active {
                        filter: brightness(0.65);
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 1300px) {

    .rapporteringer_container {

        .authenticated_show_data_wrapper {
    
            width: 75%;
        }
    }
}


@media screen and (max-width: 800px) {

    .rapporteringer_container {

        .authenticated_show_data_wrapper {
            table {
                tr {
        
                    .table_header_sortering {
                        height: 40px;
        
                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;
                            text-align: center;
                            position: relative;

                            svg {
                                position: initial;
                                left: initial;
                                transform: initial;
                            }
                        }
                    }

                    .table_header_sortering_id {
                        height: 40px;
        
                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;
                            text-align: center;
                            position: relative;
    
                            svg {

                                position: initial;
                                left: initial;
                                transform: initial;
                            }
                        }
                    }

        
                }
            }
        }
    }

}