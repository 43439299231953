

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    position: relative;
    font-family: 'Lato';

    .siste_registrering_slette_farge {
        color: $nxtech-light
    }

    .diagram_siste_30_dager {
        margin-top: $header_h1_height;
        width: 750px;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;

        h2 {
            margin-top: $header_h1_height;
            margin-bottom: 1rem;
            font-size: 2rem;
            position: relative;
            margin-bottom: 1rem;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                height: 2px;
                bottom: -4px;
                left: -1rem;
                right: -1rem;
                z-index: -10;
                background-color: $nxtech-lighter;
            }





        }


    }

    .info_button {
        background-color: transparent;
        outline: none;
        border: none;
        position: absolute;

        top: 62.5%;
        transform: translateY(-62.5%);
        right: -2rem;

        color: $primary-white;

        cursor: pointer;

        &:hover {
            color: $nxtech_slett_meg_rod_hover
        }

        &:active, &:focus {
            color: $nxtech_slett_meg_rod_border
        }
    }

    .Sammenligning_info {
        position: absolute;
        width: 12rem;
        background-color: $primary-white;
        color: $primary-black;
        top: 10px;
        left: 2rem;

        border-radius: 0.6rem;
        border: 2px solid $primary-black;
        padding: 0.4rem;
        text-align: start;

        font-size: 0.967rem;

        z-index: 100;
    }
    
    .diagram_doughnut {
        margin-top: $header_h1_height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .totalt_siden_2023 {
            position: relative;
            margin-bottom: 1rem;
            font-size: 2rem;
            &::after {
                content: "";
                position: absolute;
                height: 2px;
                bottom: -4px;
                left: -1rem;
                right: -1rem;
                z-index: -10;
                background-color: $nxtech-lighter;
            }
        }

        canvas {
            width: 550px;
        }
    }


    .show_datetime {
        position: absolute;
        top: 0;
        left: 1rem;
        margin-left: 1rem;
        font-style: italic;
        z-index: 100;

    }

    .sorter_listevisning_container {


        .sorteringsKnapp {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 40px;
    
            padding: 1rem;
    
            background-color: $nxtech-dark;
    
            border-radius: 0.25rem;
            transition: filter .23s ease;
    
            &:hover {
                filter: brightness(1.2);
                cursor: pointer;
            }
    
            &:active {
                filter: brightness(0.85);
                cursor: pointer;
            }
    
        }

        .sorteringsValg {
            height: 40px;
            ul {
                display: flex;
                gap: 1.5rem;

                li {
                    list-style-type: none;
                    position: relative;
                    font-size: 1.2rem;


                    a {
                        text-decoration: none;
                    }

                    &:hover {
                        cursor: pointer;
                        color: $nxtech-light;
                    }
                    
                }

                .activeSorteringsValg {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        left: 0;
                        bottom: -5px;
                        background-color: $nxtech-dark;
                    }

                    .sortert {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        color: $primary-white;
                        font-size: 1rem;
                        margin: 0;
                        bottom: -1.5rem;
                        
                    }
                }

                .close_sorted_options {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

    }



    .målinger {
        margin-top: 3rem;

        display: flex;
        justify-content: space-evenly;
        gap: 5rem;

        .oversikt_vekt {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            h2 {
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }
            ul {
                display: flex;
                flex-direction: column;
    
                border: 1px solid white;
                border-radius: 0.5rem;
                padding: 0.5rem;

    
                li {
                    display: flex;
                    justify-content: space-between;
    
                    min-width: 220px;
                    border-bottom: 1px solid white;
                    padding: 0.5rem;
                    gap: 1.21rem;
    
    
                    &:nth-last-child(1) {
                        border-bottom: 0;
                    }
                }


            }
        }
    }


    .ekstra_features {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        gap: 0.75rem;

        .button_rapporteringer {
            width: 160px;
            height: 40px;
            font-size: 1.2rem;
    
            border-radius: 1.2rem;
            background-color: $nxtech-darker;
            color: $primary-white;
            outline: none;
            border: 1px solid white;
    
            cursor: pointer;
        }

        h2 {
            font-size: 2rem;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 120%;
                height: 2px;
                bottom: 0px;
                left: -10%;
                background-color: $nxtech-lighter;
            }
        }

        .siste_registrerte_feature {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;


            li {
                font-size: 1.5rem;
            }

            .slett_meg_knapp {

                display: flex;
                align-items: center;
                justify-content: center;
                button {    
                    width: 5rem;
                    height: 30px;
                    font-size: 1rem;
                    border: 1px solid $nxtech_slett_meg_rod_border;
                    color: $nxtech_slett_meg_rod_tekst;

                    cursor: pointer;
                    background-color: $nav-bg;
                    transition: filter .2s ease;
                    transition: background-Color .19s ease-out;

                    &:hover {
                    background-color: $nxtech_slett_meg_rod_hover;
                    color: $primary-white;
                    border: 1px solid $nxtech_slett_meg_rod_tekst
                    }

                    &:active {
                        filter: brightness(0.65);
                    }
                }
            }

            table {
                border: 2px solid $primary-white;


                tr {
                    height: 2.5rem;
                    th {
                        margin: 0;
                        border-bottom: 2px solid $primary-white;



                    }
                    td {
                        padding: 0 1rem;
                    }
                }
            }
        }

    }

}


@media screen and (max-width: 750px) and (min-width: 350px) {
    .home {
        margin-top: 4rem;
    
        .show_datetime {
            top: -3rem;
        }


        .diagram_siste_30_dager {
            margin-top: $header_h1_height;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 375px;

            canvas {
            }
        }


        .diagram_doughnut {
            canvas {
                width: 350px;
            }
        }
    
        .info_button {
            
        }
    
        .Sammenligning_info {
            position: absolute;
            width: 12rem;
            background-color: $primary-white;
            color: $primary-black;
            top: 2.2rem;
            left: -11rem;
    
            border-radius: 0.6rem;
            border: 2px solid $primary-black;
            padding: 0.4rem;
            text-align: start;
    
            font-size: 0.967rem;
    
            z-index: 100;
        }
    
        .målinger {
            margin-top: 3rem;
            flex-direction: column;

            .oversikt_vekt {


                ul {

                    li {
                        width: 250px;
                        h3 {
                            font-size: 1.5rem;
                        }


                    }
                }
            }
        }
    
    }
}

@media screen and (max-width: 349px) {
    .home {
        margin-top: 4rem;
    
        .show_datetime {
            top: -3rem;
        }
        


        .diagram_siste_30_dager {
            margin-top: $header_h1_height;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 280px;

            h2 {
                font-size: 1.8rem;
            }

            canvas {
                height: 400px;
            }
        }


        .diagram_doughnut {
            canvas {
                width: 250px;
            }
        }


        .Sammenligning_info {
            position: absolute;
            width: 12rem;
            background-color: $primary-white;
            color: $primary-black;
            top: 2.2rem;
            left: -11rem;
    
            border-radius: 0.6rem;
            border: 2px solid $primary-black;
            padding: 0.4rem;
            text-align: start;
    
            font-size: 0.967rem;
    
            z-index: 100;
        }
    
        .ekstra_features {
            h2 {
                font-size: 1.5rem;
            }
        }

        .sorter_listevisning_container {
            height: 100%;
            .sorteringsValg {
                height: 180px;
                ul {
                    display: flex;
                    flex-direction: column;
                    height: 180px;
                    gap: 1.5rem;
    
                    li {
                        list-style-type: none;
                        position: relative;
                        font-size: 1.2rem;
    
                        
                    }
    
                    .activeSorteringsValg {
                        height: 180px;

                        
    
                        .sortert {

                            
                        }
                    }


    
                    .close_sorted_options {

                    }
                }
            }

            .sorteringsKnapp {
                height: 40px;
            }
    
        }
    
        .målinger {
            margin-top: 2rem;
            flex-direction: column;

            .oversikt_vekt {

    
                h2 {
                    font-size: 1.8rem;
                }
                ul {

                    li {
                        width: 250px;
                        h3 {
                            font-size: 1.5rem;
                        }

                        &:nth-last-child(1) {
                        }
                    }
                }
            }
        }
    
    }
}





@media screen and (min-width: 2000px) {
    .home {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        position: relative;
        font-family: 'Lato';

        .show_datetime {
            font-size: 2.4rem;
            margin-left: 4rem;

        }
    
        .diagram_siste_30_dager {
            margin-top: $header_h1_height;
            min-width: 85%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                font-size: 5rem;

            }

    
        }


    .info_button {
        background-color: transparent;
        outline: none;
        border: none;
        position: absolute;


        top: 62.5%;
        transform: translateY(-62.5%);
        right: -5.5rem;

        color: $primary-white;

        cursor: pointer;

        &:hover {
            color: $nxtech_slett_meg_rod_hover
        }

        &:active, &:focus {
            color: $nxtech_slett_meg_rod_border
        }

        svg {
            font-size: 5rem;
            width: 65px;
            height: 65px;
        }
    }

    .Sammenligning_info {
        position: absolute;
        width: 30rem;
        background-color: $primary-white;
        color: $primary-black;
        top: 0px;
        left: 5.5rem;

        border-radius: 0.6rem;
        border: 2px solid $primary-black;
        padding: 0.4rem;
        text-align: start;

        font-size: 2rem;

        z-index: 100;
    }
        
        .diagram_doughnut {
            margin-top: $header_h1_height;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            
            .totalt_siden_2023 {
                position: relative;
                margin-top: 3rem;
                margin-bottom: 1rem;
                font-size: 5rem;

                &::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    bottom: -4px;
                    left: -1rem;
                    right: -1rem;
                    background-color: $nxtech-lighter;
                }
            }
    
            canvas {
                width: 1000px;
            }
        }
    
    
        .show_datetime {
            position: absolute;
            top: 0;
            left: 1rem;
            font-style: italic;
            z-index: 100;
    
        }
    
        .sorter_listevisning_container {
    
    
            .sorteringsKnapp {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;
                height: 90px;
        
                padding: 1rem;
        
                background-color: $nxtech-dark;
        
                border-radius: 0.25rem;
                transition: filter .23s ease;
                font-size: 2rem;
        
                &:hover {
                    filter: brightness(1.2);
                    cursor: pointer;
                }
        
                &:active {
                    filter: brightness(0.85);
                    cursor: pointer;
                }
        
            }
    
            .sorteringsValg {
                height: 90px;
                ul {
                    display: flex;
                    gap: 5rem;
    
                    li {
                        list-style-type: none;
                        position: relative;
                        font-size: 3rem;
    
    
                        a {
                            text-decoration: none;
                        }
    
                        &:hover {
                            cursor: pointer;
                            color: $nxtech-light;
                        }
                        
                    }
    
                    .activeSorteringsValg {
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            left: 0;
                            bottom: -5px;
                            background-color: $nxtech-dark;
                        }
    
                        .sortert {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            color: $primary-white;
                            font-size: 1rem;
                            margin: 0;
                            bottom: -1.5rem;
                            
                        }
                    }
    
                    .close_sorted_options {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
    
        }
    
    
    
        .målinger {
            margin-top: 3rem;
    
            display: flex;
            justify-content: space-evenly;
            gap: 5rem;
            width: 90%;
    
            .oversikt_vekt {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                min-width: 45%;
    
                h2 {
                    font-size: 5rem;
                    margin-bottom: 3rem;
                }
                ul {
                    display: flex;
                    flex-direction: column;
        
                    border: 3px solid white;
                    border-radius: 0.5rem;
                    padding: 0.5rem;
                    min-width: 100%;
    
        
                    li {
                        display: flex;
                        justify-content: space-between;
        
                        min-width: 100%;
                        border-bottom: 3px solid white;
                        padding: 0.5rem;
                        gap: 1.5rem;
                        height: 90px;
                        margin-bottom: 1rem;

                        font-size: 2rem;
        
        
                        &:nth-last-child(1) {
                            border-bottom: 0;
                            margin-bottom: 0rem;

                        }
                    }
    
    
                }
            }
        }
    
    
        .ekstra_features {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 4rem;
            gap: 0.75rem;
    
            h2 {
                font-size: 5rem;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 120%;
                    height: 2px;
                    bottom: 0px;
                    left: -10%;
                    background-color: $nxtech-lighter;
                }
            }

            h3 {
                font-size: 2.5rem;
            }


            .button_rapporteringer {
                width: 300px;
                height: 50px;
                border-radius: 2rem;
                font-size: 2rem;
            }
    
            .siste_registrerte_feature {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4rem;
    
                table {
                    tr {
                        th {
                            font-size: 3rem;
                            padding: 0.5rem 2rem;
                        }

                        td {
                            font-size: 3rem;
                            padding: 0.5rem 2rem;

                        }
                    }
                }
    
                .slett_meg_knapp {
                    
                    button {
                        width: 10rem;
                        height: 50px;
                        font-size: 2rem;
                        border: 1px solid $nxtech_slett_meg_rod_border;
                        color: $nxtech_slett_meg_rod_tekst;
    
                        cursor: pointer;
                        background-color: $nav-bg;
                        transition: filter .2s ease;
                        transition: background-Color .19s ease-out;
    
                        &:hover {
                        background-color: $nxtech_slett_meg_rod_hover;
                        color: $primary-white;
                        border: 1px solid $nxtech_slett_meg_rod_tekst
                        }
    
                        &:active {
                            filter: brightness(0.65);
                        }
                    }
                }
            }
    
        }
    
    }
}
