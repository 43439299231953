
.omoss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $header_h1_height;

    width: 100%;
    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4rem;
        border-radius: 1rem;
        padding: 1rem;

        
        .card {
            width: 250px;
            background-color: grey;
            border-radius: 1rem;
            overflow: hidden;
            height: 335px;

            cursor: pointer;
            filter: brightness(0.80);
            transition: filter .25s ease;


            .card-info {
                padding: 1rem;
            }

            .profilePicture {
                width: 250px;
            }

            &:hover {
                filter: brightness(1)
            }



            @media screen and (max-width: 950px) and (min-width: 751px) {
                &:nth-child(3) {
                    width: calc(500px + 4rem);
                    height: 250px;
                    display: flex;
                    align-items: center;

                    .profilePicture {
                        aspect-ratio: auto;
                        height: 250px;
                        width: auto;
                    }


                    h2 {
                        font-size: 3rem;
                    }
                }


            }
        }
    }
}





@media screen and (max-width: 750px) {
    .omoss {

    .cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .card {


            .card-info {
            }

            .profilePicture {
            }


            }
        }
    }


}